<template>
  <div class="pay-details">
    <div
      class="pay-top-info"
      :style="{ height: productType == 2 ? '4.3rem' : '4.3rem' }"
    >
      <div
        class="success-title"
        :style="{ marginTop: productType == 2 ? '0.72rem' : '0.9rem' }"
      >
        <van-icon class="pay-icon" name="checked" />{{ successText }}
      </div>
      <div class="pay-money">
        <!-- {{ `支付 ${"￥30.00"}${" + "}${"3000积分"}` }} -->
        {{
          `支付 ￥${
            payInfo.orderRealMoney
              ? mathManage.formatMoney(payInfo.orderRealMoney, 2)
              : "0.00"
          }元`
        }}
      </div>
      <!-- <div v-if="productType == 2" class="secret-goods">
        <div class="secret-mobile">接收卡密手机号：132****5172</div>
        <div class="secret-tip">
          购买的商品卡密将在15分钟内直接发送至您的登录手机号，请注意查收
        </div>
      </div> -->
      <div class="secret-goods">
        <div v-if="payInfo.couponPrice" class="secret-mobile">
          优惠立减 {{ mathManage.formatMoney(payInfo.couponPrice, 2) }}元
        </div>
      </div>
      <div class="pay-action">
        <span class="to-home" @click="toHome('/mall/home')">返回首页</span>
        <span class="to-order" @click="toOrderDetail('/mall/orderDetails')"
          >查看订单</span
        >
      </div>
    </div>
    <div class="pay-you-like">
      <div class="like-title">
        <img src="../assets/images/line.png" alt="" />
        <span>猜你喜欢</span>
        <img src="../assets/images/line.png" alt="" />
      </div>
      <div class="like-goods-list">
        <!-- <digital-goods /> -->
        <product-list :data="goodsList" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import DigitalGoods from "../components/DigitalGoods.vue";
import { orderPayResultQuery, digitalproductpage } from "@/api/common";
import ProductList from "@/components/ProductList.vue";
import mathManage from "@/utils/mathManage";
export default {
  name: "PayDetails",
  components: {
    DigitalGoods,
    ProductList,
    [Icon.name]: Icon,
  },
  data() {
    return {
      mathManage,
      productType: 2,
      successText: "处理中...",
      timer: null,
      goodsList: [],
      payInfo: {},
      count: 0,
    };
  },
  created() {
    document.title = "支付详情";
    const orderId = this.$route.query.id;
    this.orderId = orderId;
    this.getResult();
    this.getDataList();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    // 获取支付结果
    getResult() {
      this.$loading("支付中...");
      this.timer = setInterval(() => {
        this.getPayResult();
      }, 1000);
    },
    async getPayResult() {
      if (this.count > 10) {
        this.$toast.clear();
        clearInterval(this.timer);
        return;
      }
      this.count += 1;
      try {
        const res = await orderPayResultQuery({
          orderId: this.orderId,
        });
        if (res && res.code == 0) {
          this.payInfo = res.data || {};
          if (res.data.payStatus == 30 || res.data.payStatus == 40) {
            this.$toast.clear();
            clearInterval(this.timer);
            this.successText =
              res.data.payStatus == 30 ? "支付成功！" : "支付失败！";
          }
        } else {
          this.$toast.clear();
          clearInterval(this.timer);
          this.$toast(res.message);
          // this.$router.push("/mall/orderList");
        }
      } catch (error) {
        this.$toast.clear();
        clearInterval(this.timer);
        // this.$router.push("/mall/orderList");
      }
    },
    // 跳转首页
    toHome(path) {
      this.$router.push(path);
    },

    // 订单结果
    toOrderDetail() {
      this.$router.push(`/mall/orderDetails/${this.orderId}`);
    },

    // 获取猜你喜欢列表
    async getDataList() {
      const res = await digitalproductpage({
        ProductTag: configs.tagProduct[2],
        PageIndex: 1,
        PageSize: 4,
        ProductStatus: 10,
      });
      if (res && res.code == 0) {
        this.goodsList = res.data.list || [];
      } else {
        this.$toast(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pay-details {
  height: 100%;
  overflow-y: auto;

  .pay-top-info {
    height: 4.12rem;
    background-image: url("../assets/images/account_bg.png");
    background-size: 100% 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .success-title {
      font-size: 0.56rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f6f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.9rem;

      .pay-icon {
        color: #f6f7f7;
        margin-right: 0.2rem;
      }
    }

    .secret-goods {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0 0.6rem;
      margin-top: 0.22rem;
      opacity: 0.9;

      .secret-mobile {
        text-align: center;
      }
      .secret-tip {
        text-align: center;
      }
    }

    .pay-money {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f6f7f7;
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pay-action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.36rem;

      span {
        width: 2.08rem;
        height: 0.72rem;
        border-radius: 0.46rem;
        border: 0.02rem solid #f6f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f6f7f7;

        &.to-order {
          background-color: #fff;
          border-color: #fff;
          color: #ff6043;
          margin-left: 0.48rem;
        }
      }
    }
  }

  .pay-you-like {
    background: linear-gradient(180deg, #ffffff 0%, #f6f7f7 100%);
    border-radius: 0.24rem 0.24rem 0rem 0rem;
    padding: 0.54rem 0.4rem;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    top: -0.16rem;

    .like-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      font-size: 0.36rem;
      font-family: Helvetica;
      color: #000000;
      margin: 0 0.08rem;
    }

    img {
      height: 0.2rem;
      width: 0.8rem;
    }
  }

  .like-goods-list {
    margin-top: 0.3rem;
  }
}
</style>